import React from "react"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import Seo from "../../../components/Seo"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import RubyConverterTool from "../../../utils/RubyConverterTool"
import RubyConvert from "../../../components/RubyConvert"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import FlagVn from "../../../images/flag_vn.svg"
import FlagJp from "../../../images/flag_jp.svg"

import FaceImg01 from "../../../images/interview/02/face_01.jpg"
import FaceImg02 from "../../../images/interview/02/face_02.jpg"

import css from "../stylesheets/interview.module.scss"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo title={`自分の心と体を大切にしながら働く | INTERVIEW`} />
      <LayoutInterview lang={`easyja`}>
        <Info2column id={`interview-02`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>
              <RubyConvert>{`{[自分/じぶん]の}{[心/こころ]と[体/からだ]を}{[大切/たいせつ]にしながら}{[働/はたら]く}`}</RubyConvert>
            </h3>
            <p>
              <RubyConvert>{`{[斉藤/さいとう][健一/けんいち]さん}`}</RubyConvert>
              <small>
                <RubyConvert>{`{（39[歳/さい]）}`}</RubyConvert>
              </small>
              × <RubyConvert>{`{トゥイーさん}`}</RubyConvert>
              <small>
                <RubyConvert>{`{（26[歳/さい]）}`}</RubyConvert>
              </small>{" "}
            </p>
            <p>
              <RubyConvert>{`{トゥイーさんは}`}</RubyConvert>
              <span className="phrase">
                <AnchorLink
                  className={`linkUnderline`}
                  to="/basic-information/kaigo/#section-2"
                >
                  EPA
                  <RubyConvert>{`[介護/かいご][福祉士/ふくしし][候補者/こうほしゃ]`}</RubyConvert>
                </AnchorLink>
                です。
              </span>
              <RubyConvert>{`{ベトナムから}{[来/き]ました。}{[日本/にほん]に}{[来/き]てから}{2か[月/げつ][半/はん]}{[研修/けんしゅう]を}{[受/う]けました。}{その}{[後/あと]、}{[長野県/ながのけん]}{[塩尻市/しおじりし]に}{ある}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム}『`}</RubyConvert>
              <ModalLink
                id={`facility`}
                className={`linkUnderline`}
                style={{ display: `inline-block` }}
              >
                <span style={{ color: "black" }}>
                  <RubyConvert>{`グレイスフル[塩尻/しおじり]`}</RubyConvert>
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>
                    <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
                  </h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                </div>
              </ModalLink>
              <RubyConvert>{`』{で}{[働/はたら]きはじめました。}{ここで}{[働/はたら]きはじめたのは}{[約/やく]2[年/ねん][前/まえ]です。}`}</RubyConvert>
            </p>
            <p>
              <RubyConvert>{`{[斉藤/さいとう][健一/けんいち]さんは}{[介護/かいご][長/ちょう]です。}{[斉藤/さいとう]さんは「トゥイーさんは}{[介護/かいご]の}{センスが}{ある」と}{[話/はな]します。}{[施設/しせつ]には}{トゥイーさんの}{ほかにも}{ベトナム[人/じん]の}{[介護者/かいごしゃ]が}{たくさん}{います。}{[仕事/しごと]について、}{それぞれの}{[立場/たちば]から}{[話/はなし]を}{[聞/き]きました。}`}</RubyConvert>
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable profileTableIsWideLeft`}>
              <tbody>
                <tr>
                  <th>
                    <RubyConvert>{`{[名前/なまえ]}`}</RubyConvert>
                  </th>
                  <td>グエン ゴック トゥイーさん</td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[勤務先/きんむさき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{[社会/しゃかい][福祉/ふくし][法人/ほうじん]サン・ビジョン}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム}{『グレイスフル[塩尻/しおじり]』}{[介護/かいご][職員/しょくいん]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[出身地/しゅっしんち]}`}</RubyConvert>
                  </th>
                  <td>
                    <img src={FlagVn} alt="ベトナム国旗" className={`flag`} />
                    ベトナム・ハノイ
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[年齢/ねんれい]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{26[歳/さい]}{（1993[年/ねん][生/う]まれ）}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[経歴/けいれき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{ハノイ[医療/いりょう][短期/たんき][大学/だいがく]}{[看護/かんご][学科/がっか]を}{[卒業/そつぎょう]した}{[後/あと]、}{[総合/そうごう][病院/びょういん]の}{[看護/かんご][師/し]として}{1[年間/ねんかん]}{[働/はたら]く}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[入国/にゅうこく]した}{ときの}{[在留資格/ざいりゅうしかく]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`EPA[介護/かいご][福祉士/ふくしし][候補者/こうほしゃ]`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[来日/らいにち]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{2018[年/ねん]5[月/がつ]}`}</RubyConvert>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={`profileTable profileTableIsWideLeft`}>
              <tbody>
                <tr>
                  <th>
                    <RubyConvert>{`{[名前/なまえ]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`[斉藤/さいとう][健一/けんいち]さん`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[勤務先/きんむさき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{[社会/しゃかい][福祉/ふくし][法人/ほうじん]サン・ビジョン}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム}{『グレイスフル[塩尻/しおじり]』}{[介護/かいご][長/ちょう]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[出身地/しゅっしんち]}`}</RubyConvert>
                  </th>
                  <td>
                    <img src={FlagJp} alt="日本国旗" className={`flag`} />
                    <RubyConvert>{`{[長野県/ながのけん]}{[諏訪市/すわし]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[年齢/ねんれい]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{39[歳/さい]}{（1981[年/ねん][生/う]まれ）}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[経歴/けいれき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{[桃山/ももやま][学院/がくいん][大学/だいがく]}{[社会/しゃかい][学部/がくぶ]}{[社会/しゃかい][福祉/ふくし][学科/がっか]を}{[卒業/そつぎょう]}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[入社/にゅうしゃ]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{2003[年/ねん]4[月/がつ]}`}</RubyConvert>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[介護/かいご]の}{[仕事/しごと]は}{どうですか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>
                  {`{やる}{ことが}{たくさん}{あるので}{[大変/たいへん]ですが、}{[慣/な]れてきたら}{[毎日/まいにち]}{[楽/たの]しいです。}`}
                </RubyConvert>
                <RubyConvert>
                  {`{[初/はじ]めの}{[半年間/はんとしかん]は、}{[先輩/せんぱい]と}{[一緒/いっしょ]に、}{[少/すこ]しずつ}{[仕事/しごと]を}{しました。}{[利用者/りようしゃ]さんたちと}{[毎日/まいにち]}{[話/はな]していると、}{[気持/きも]ちや}{[性格/せいかく]が}{わかるように}{なってきました。}{そう}{したら、}{[介護/かいご]も}{うまく}{できるように}{なってきました。}`}
                </RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                <RubyConvert>{`{トゥイーさんは、}{[明/あか]るくて、}{[利用者/りようしゃ]さん}{[一人/ひとり]ひとりを}{[大切/たいせつ]にしています。}{[利用者/りようしゃ]さんや}{そのときの}{[状況/じょうきょう]に}{あわせて}{[介護/かいご]する}{ことが}{できます。}{このあいだは、}{[夕方/ゆうがた]に}{[利用者/りようしゃ]さんの}{ひげを}{[剃/そ]っていましたよね。}{あれは}{なぜですか？}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{[肌/はだ]に}{[薬/くすり]を}{[塗/ぬ]る}{[必要/ひつよう]が}{あったからです。}{ひげが}{ないほうが}{[薬/くすり]を}{[塗/ぬ]りやすいです。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                <RubyConvert>{`{なるほど。}{[一人/ひとり]ひとりについて「いまは}{こう}{した}{ほうが}{いい」と}{[気/き]づく}{[力/ちから]が}{ありますね。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{「がんばって}{[気/き]づこう」と}{[思/おも]わなくても}{[気/き]づく}{ことが}{[多/おお]いです。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{わたしにとって}{[難/むずか]しいのは、}`}</RubyConvert>
                <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                  <span>
                    <RubyConvert>{`[個別/こべつ]ケア`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>
                      <RubyConvert>{`[個別/こべつ]ケア`}</RubyConvert>
                    </h3>
                    <p>
                      <RubyConvert>{`{[多/おお]くの} {[人/ひと]と} {[一緒/いっしょ]に} {[生活/せいかつ]する} {[施設/しせつ]の} {なかでも、} {[一人/ひとり]ひとりの} {「これを} {したい」「こうだと} {いい」} {という} {[思/おも]いを} {[大切/たいせつ]にする} {ことです。} {[必要/ひつよう]な} {[支援/しえん]を} {する} {ための「[個別/こべつ][援助/えんじょ][計画/けいかく]」を} {つくります。} {[利用者/りようしゃ]の} {[毎日/まいにち]の} {[様子/ようす]を} {[見/み]て、} {[状態/じょうたい]に} {あわせた} {[介護/かいご]を} {します。}`}</RubyConvert>
                    </p>
                  </div>
                </ModalLink>
                <RubyConvert>{`{です。}{[利用者/りようしゃ]さんは}{[一人/ひとり]ひとり}{[体/からだ]の}{[状態/じょうたい]も}{[性格/せいかく]も}{[違/ちが]います。}{[初/はじ]めは、}{[手助/てだす]けしすぎてしまう}{ことも}{ありました。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                <RubyConvert>{`{すべて}{[手助/てだす]けした}{ほうが、}{わたしたちは}{[楽/らく]かもしれませんね。}{でも}{[実際/じっさい]には、}{[利用者/りようしゃ]さんが}{[自分/じぶん]で}{できそうな}{ことが}{[少/すこ]しでも}{あれば、}{[手助/てだす]けしないで}{[自分/じぶん]で}{できるように}{します。}{それが}{[介護/かいご]です。} `}</RubyConvert>
                <br />
                <RubyConvert>{`{そのように、}{[一/ひと]つひとつ}{[考/かんが]えていくのが、}{[介護/かいご]の}{おもしろい}{ところだと}{[思/おも]います。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg01} name={`トゥイー さん`}>
                <p>
                  <RubyConvert>{`{そうですね。}{[一人/ひとり]ひとりと}{[話/はな]して、}{[相手/あいて]を}{[知/し]る}{ことが}{[大切/たいせつ]だと}{[思/おも]います。}`}</RubyConvert>
                </p>
              </CommentText>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`斉藤 さん`}
              >
                <p>
                  <RubyConvert>{`{そのためには}{コミュニケーションが}{[大切/たいせつ]ですが、}{みんな「[方言/ほうげん]が}{[難/むずか]しい」と}{[言/い]っていますよね。}{トゥイーさんも}{わからない}{[言葉/ことば]が}{ありましたか？}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{[利用者/りようしゃ]さんに「[服/ふく]を}{[着替/きが]えますか？」と}{[聞/き]いて}{「[着替/きが]えん」と}{[言/い]われた}{ことが}{あります。}{わたしには「[着替/きが]える」と}{[聞/き]こえたので、}{そのまま}{パジャマを}{[着替/きが]える}{お[手伝/てつだ]いを}{しようと}{しました。}{そう}{したら、}{「[着替/きが]えんと}{[言/い]ったでしょ」と}{[言/い]われました。}{[先輩/せんぱい]に}{[相談/そうだん]したら、}{[長野県/ながのけん]の}{[方言/ほうげん]では}{「[着替/きが]えない」}{を「[着替/きが]えん」と}{[言/い]う}{ことを}{[知/し]りました。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{わからない}{ときは、}{わかるまで}{[質問/しつもん]するように}{しています。}{「もう[一度/いちど]}{お[願/ねが]いします」「[紙/かみ]に}{[書/か]いてください」}{「ほかの}{[言葉/ことば]で}{[何/なん]と}{[言/い]いますか？」}{「わたしは}{[外国人/がいこくじん]で}{まだ}{[日本語/にほんご]が}{よく}{わからないので、}{[教/おし]えてください」と}{[言/い]うと、}{みなさん}{ゆっくり}{[丁寧/ていねい]に}{[教/おし]えてくれます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[働/はたら]いている}{[施設/しせつ]は}{どんな}{ところですか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{「リフト」や}{「スライディングボード」}{など}{[移乗/いじょう]を}{サポートする}{[福祉/ふくし][用具/ようぐ]を}{[使/つか]っています。}{まだ}{ベトナムには}{[福祉/ふくし][用具/ようぐ]が}{あまり}{ないので、}{このような}{[技術/ぎじゅつ]を}{ぜひ}{[持/も]って[帰/かえ]りたいと}{[思/おも]っています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image6.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                <RubyConvert>{`{わたしたちは}{「ノーリフティングポリシー」を}{[大切/たいせつ]にしています。}{ノーリフティングポリシーとは、}{「[抱/かか]える」「[持/も]ち[上/あ]げる」のような}{[動作/どうさ]を}{できるだけ}{[少/すく]なくする}{という}{[考/かんが]え[方/かた]です。}{[利用者/りようしゃ]さんの}{[負担/ふたん]が}{[減/へ]りますし、}{[介護者/かいごしゃ]の}{[負担/ふたん]も}{[減/へ]ります。}{そのために、}{[福祉/ふくし][用具/ようぐ]を}{[使/つか]っています。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{また、}{[介護者/かいごしゃ]の}`}</RubyConvert>
                <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                  <span>
                    セルフケア
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>セルフケア</h3>
                    <p>
                      <RubyConvert>{`{[自分/じぶん]の} {[健康/けんこう]を} {[自分/じぶん]で} {[管理/かんり]する} {ことです。} {[仕事/しごと]での} {[疲/つか]れや} {ストレスを} {[上手/じょうず]に} {[減/へ]らす} {ことや、} {[何/なに]かを} {[楽/たの]しむ} {こと} {などで、} {[心/こころ]と} {[体/からだ]を} {[健康/けんこう]に} {します。}`}</RubyConvert>
                    </p>
                  </div>
                </ModalLink>
                <RubyConvert>{`{も}{[大切/たいせつ]にしています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[一緒/いっしょ]に}{[働/はたら]くために}{[工夫/くふう]している}{ことは}{ありますか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                <RubyConvert>{`{わたしたちの}{[施設/しせつ]には、}{ベトナム[人/じん]の}{EPA[介護/かいご][福祉士/ふくしし][候補者/こうほしゃ]が}{14[人/にん]}{[働/はたら]いています。}{1か[月/げつ]に}{[約/やく]20[時間/じかん]、}{[彼/かれ]らが}{[日本語/にほんご]を}{[勉強/べんきょう]する}{[時間/じかん]を}{つくっています。}{1か[月/げつ]に}{4[回/かい]は}{[日本語/にほんご][教師/きょうし]が}{[授業/じゅぎょう]を}{します。}{2[年目/ねんめ]からは、}{1か[月/げつ]に}{1、}{2[回/かい]、}{[介護/かいご][福祉士/ふくしし][国家/こっか][試験/しけん]のための}{[授業/じゅぎょう]も}{あります。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{2016[年/ねん]に}{[初/はじ]めて}{ベトナム[人/じん]を}{[受/う]け[入/い]れた}{ときには、}{[何/なに]を}{どう}{サポートしたら}{いいのか、}{わからない}{こと}{ばかりでした。}{でも、}{[彼/かれ]らと}{[働/はたら]いて}{わかってきた}{ことが}{あります。}{それは、}{[彼/かれ]らは}{[困/こま]っていても、}{「[助/たす]けてほしい」と}{あまり}{[言/い]わない}{という}{ことでした。}{[彼/かれ]らは}{ほかの}{[人/ひと]に}{[迷惑/めいわく]を}{かけたくないと}{[思/おも]っているからです。}{その}{ことが}{わかったので、}{[彼/かれ]らが「[大丈夫/だいじょうぶ]です」と}{[言/い]っても、}{わたしは}{[別/べつ]の}{[言葉/ことば]で}{[質問/しつもん]したり、}{もう[一度/いちど]}{[質問/しつもん]したり}{しています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{[斉藤/さいとう]さんは}{わたしに}{いつも「[元気/げんき]ですか？」と}{[聞/き]いてくれますね。}{わたしが「[元気/げんき]ですよ」と}{[答/こた]えても、}{「[本当/ほんとう]？」と、}{[何度/なんど]も}{[聞/き]いてくれます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                <RubyConvert>{`{いまは}{ベトナム[人/じん]の}{[先輩/せんぱい]が}{[後輩/こうはい]を}{[助/たす]けてくれるので、}{わたしが}{[助/たす]ける}{ことは}{あまり}{ありません。}{でも、}{もし}{[困/こま]ったら}{わたしに「[困/こま]った」と}{[言/い]える}{[関係/かんけい]に}{したいですね。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{2019[年/ねん]には、}{[日本人/にほんじん]スタッフと}{[一緒/いっしょ]に}{ベトナムについての}{[勉強会/べんきょうかい]と}{[食事会/しょくじかい]を}{しました。}{ベトナムと}{[日本/にほん]の}{[違/ちが]いを}{[知/し]れば、}{[一緒/いっしょ]に}{[働/はたら]きやすくなると}{[考/かんが]えました。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{[日本人/にほんじん]は}{[仲間/なかま]が}{ミスを}{しても「[大丈夫/だいじょうぶ]」と}{[言/い]って}{[間違/まちが]いだと}{[言/い]わない}{ことが}{よく}{あります。}{でも、}{それだと、}{わたしたちは}{[間違/まちが]いに}{[気/き]づかないまま、}{[同/おな]じ}{[間違/まちが]いを}{してしまいます。}{だから}{[勉強会/べんきょうかい]で「わたしたちが}{[間違/まちが]えた}{ことが}{あったら}{すぐに}{[教/おし]えてください」と}{お[願/ねが]いしました。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                <RubyConvert>{`{[文化/ぶんか]の}{[違/ちが]いを}{[知/し]れたのは}{よかったですね。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>

            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{[勉強会/べんきょうかい]の}{[後/あと]は、}{みんな}{[間違/まちが]いを}{[教/おし]えてくれるように}{なりました。}{「ゆっくり}{[話/はな]してください」とも}{お[願/ねが]いしたら、}{コミュニケーションが}{とりやすくなりました。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[日本/にほん]では}{どんな}{[生活/せいかつ]を}{していますか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image9.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img
                fluid={data.image10.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image11.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{[同/おな]じ}{[施設/しせつ]で}{[働/はたら]く}{ベトナム[人/じん]と}{3[人/にん]で}{アパートに}{[住/す]んでいます。}{アパートは}{[会社/かいしゃ]が}{[用意/ようい]してくれました。}{[施設/しせつ]までは、}{[自転車/じてんしゃ]で}{[約/やく]10[分/ぷん]です。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{[食事/しょくじ]は、}{[朝/あさ]・[昼/ひる]・[夜/よる]}{すべて}{[自分/じぶん]で}{つくっています。}{[施設/しせつ]で}{[食事/しょくじ]を}{[頼/たの]む}{ことも}{できますが、}{わたしは}{[毎日/まいにち]}{お[弁当/べんとう]を}{[持/も]って[行/い]きます。}{ルームメイトも}{[自分/じぶん]で}{ごはんを}{つくります。}{でも、}{[2人/ふたり]が}{[休/やす]みで}{[1人/ひとり]が}{[仕事/しごと]の}{[日/ひ]は、}{[休/やす]みの}{[人/ひと]たちが}{ベトナム[料理/りょうり]を}{つくって、}{みんなで}{[食/た]べます。}{[料理/りょうり]が}{[上手/じょうず]な}{[人/ひと]が}{います。}{わたしは}{[片付/かたづ]けを}{します（[笑/わらい]）。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{[必要/ひつよう]な}{[食材/しょくざい]は}{ほとんど}{スーパーで}{[買/か]えます。}{ベトナムの}{[調味料/ちょうみりょう]や}{[日本/にほん]では}{あまり}{[売/う]っていない}{[鴨/かも]の}{[肉/にく]}{などは、}{ベトナムの}{オンラインショップで}{まとめて}{[買/か]っています。}{[不便/ふべん]だと}{[思/おも]う}{ことは}{ありませんね。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{[休/やす]みの}{[日/ひ]は、}{[長野県/ながのけん]で}{[一番/いちばん]}{にぎやかな}{[松本市/まつもとし]に}{[行/い]くのが}{[好/す]きです。}{ショッピングモールで}{[買/か]い[物/もの]を}{したり、}{[松本城/まつもとじょう]の}{まわりを}{[散歩/さんぽ]したり}{します。}{[電車/でんしゃ]にも}{[慣/な]れました。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{[一緒/いっしょ]に}{[働/はたら]いている}{ベトナム[人/じん]の}{[仲間/なかま]が}{たくさん}{いるので、}{あまり}{さびしくないです。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{これからの}{[目標/もくひょう]を}{[教/おし]えてください。}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`トゥイー さん`}>
              <p>
                <RubyConvert>{`{まずは、}{[日本語/にほんご][能力/のうりょく][試験/しけん]で}{N1に}{[合格/ごうかく]する}{ことです。}{2[年/ねん][後/ご]には、}{[介護/かいご][福祉士/ふくしし][国家/こっか][試験/しけん]に}{[合格/ごうかく]したいです。}{その}{[後/あと]は、}{ベトナムに}{[帰/かえ]って}{[日本/にほん]の}{[介護/かいご]の}{[技術/ぎじゅつ]を}{[教/おし]えたいです。}{ハノイには、}{[老人/ろうじん]ホームは}{[少/すこ]ししか}{ありません。}{ベトナムでも}{これから}{[高齢者/こうれいしゃ]が}{[増/ふ]えていくので、}{[介護/かいご][施設/しせつ]が}{[必要/ひつよう]に}{なってくると}{[思/おも]います。}`}</RubyConvert>
                <br />
                <RubyConvert>{`{いま}{わたしは、}{[利用者/りようしゃ]の}{みなさんの}{ことを、}{[自分/じぶん]の}{[家族/かぞく]のように}{[感/かん]じています。}{わたしが}{ちょっと}{[元気/げんき]が}{ない}{ときには、}{[利用者/りようしゃ]さんが}{[元気/げんき]にしてくれる}{ことも}{あります。}{[利用者/りようしゃ]さんと}{[話/はな]すのが}{[楽/たの]しくて、}{[疲/つか]れを}{[忘/わす]れる}{ことも}{あります。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`斉藤 さん`}>
              <p>
                <RubyConvert>{`{[外国人/がいこくじん][介護者/かいごしゃ]を}{[受/う]け[入/い]れるように}{なってから、}{3[年/ねん]が}{[経/た]ちました。}{[介護者/かいごしゃ]が}{[安心/あんしん]して}{[働/はたら]けるように、}{[仕事/しごと]も}{[生活/せいかつ]も}{サポートしていきたいと}{[思/おも]っています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>
                <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
              </p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    <RubyConvert>{`グレイスフル[塩尻/しおじり]`}</RubyConvert>
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>
                      <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
                    </h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                <RubyConvert>{`{[塩尻/しおじり][駅/えき]の}{[前/まえ]に}{ある、}{13[階/かい][建/だ]ての}{[建物/たてもの]です。}{[建物/たてもの]の}{なかに、}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム、}{グループホーム、}{デイサービスセンター、}{[認定/にんてい]こども[園/えん]、}{[一般/いっぱん]の}{[人/ひと]のための}{[住宅/じゅうたく]}{などが}{あります。}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム（3-9[階/かい]）は、}{それぞれの}{[階/かい]に}{[約/やく]20[人/にん]の}{[利用者/りようしゃ]が}{[暮/く]らしています。}{[介護者/かいごしゃ]は}{8[人/にん]ずつ}{[働/はたら]いています。}{[長野県/ながのけん]、}{[愛知県/あいちけん]、}{[岐阜県/ぎふけん]にも}{[施設/しせつ]が}{あります。}{2016[年/ねん]から}{70[人/にん][以上/いじょう]の}{EPA[介護/かいご][福祉士/ふくしし][候補者/こうほしゃ]を}{[受/う]け[入/い]れています。}`}</RubyConvert>
              </p>
              <p>
                <a
                  href={`https://sun-vision.or.jp`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://sun-vision.or.jp
                </a>
              </p>
              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Natsuki Kuroda
                  <br />
                  Translation by Shinsuke Hayama, Daigo Murotsu（SLOW
                  COMMUNICATION）
                </p>
              </div>

              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/1`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/3`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/02/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/02/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/02/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/02/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/02/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/02/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/02/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/02/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/02/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/02/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/02/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/02/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/02/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/02/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/02/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/02/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
